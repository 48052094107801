@import 'styles/var.scss';
@import 'styles/mixin.scss';

.container {
  line-height: 9px;
  display: flex;
  align-items: center;
}

.language {
  cursor: pointer;
  display: inline-block;
  padding-bottom: 1px;

  &:not(:last-child) {
    border-right: 1px solid rgba(250, 250, 250, 0.9);
    padding-right: 10px;
    margin-right: 10px;
  }

  &:not(.active) {
    color: $font-light-color;

    &:hover {
      color: rgba(250, 250, 250, 0.9);
    }
  }
}

@media (max-width: 767px) {
  .container {
    margin-top: 30px;
    margin-bottom: 50px;
    justify-content: center;
    font-size: 13px;
  }

  .language {
    color: $font-light-color;

    &:not(.active) {
      color: $footer-locale-color;

      &:hover {
        color: $font-light-color;
      }
    }

    &:not(:last-child) {
      border-right: 1px solid $font-light-color;
      padding-right: 15px;
      margin-right: 15px;
    }
  }
}
