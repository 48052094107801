@use "sass:math";
@import 'styles/var.scss';
@import 'styles/var.scss';

.footer {
  background-color: $bg-deep-color;
  // overflow: hidden;
  color: #a3aab5;
}

.footer-container {
  padding: 50px 0 20px;

  .footer-row {
    padding: 0 30px;
  }
}

.footer-img {
  width: 40px;
  height: 40px;
  margin: 24px auto 20px;
  cursor: pointer;

  .footer-weixin {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .footer-wechat {
    position: relative;

    &:hover .wechat {
      opacity: 1;
      display: block;
    }

    .wechat {
      position: absolute;
      left: 50%;
      margin-left: -78px;
      top: -200px;
      height: 178px;
      width: 156px;
      border-radius: 7px;
      background-color: RGBA(0, 0, 0, 0.75);
      z-index: 1001;
      transition: opacity 0.3s;
      opacity: 0;
      display: none;

      &::after {
        content: '\00a0';
        width: 0;
        height: 0;
        display: block;
        border-style: solid;
        border-width: 8px;
        border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
        position: absolute;
        z-index: -1;
        bottom: -16px;
        left: 70px;
      }
    }

    .wechat-img {
      height: 120px;
      width: 120px;
      position: relative;
    }

    .light-pc {
      position: absolute;
      top: 18px;
      left: 18px;
      right: 18px;
      height: 150px;
      width: 120px;
      // overflow: auto;

      p {
        font-size: 12px;
        color: $font-light-color;
        margin-top: 10px;
        text-align: center;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.footer-phone {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $font-light-color;
  transition: color 0.5s;
  font-size: 12px;
  line-height: 24px;
  padding-right: 16px;

  span,
  a {
    color: $font-light-color;
  }

  .nemo {
    margin: 0 37px 0 40px;
  }

  a:hover {
    color: $bg-normal-color;
  }
}

.item-group {
  text-align: center;
  line-height: 2;
  color: $bg-normal-color;
  font-size: 14px;

  color: $font-light-color;
  transition: color 0.5s;

  .item-title {
    color: white;
    font-size: 16px;
  }

  .item {
    color: $font-light-color;
    line-height: 30px;
    transition: color 0.5s;

    a {
      color: $font-light-color;

      &:hover {
        color: $bg-normal-color;
      }
    }
  }
}

.copyright {
  display: flex;
  justify-content: center;
  height: 50px;
  line-height: 50px;
  background-color: #2c2c34;
  color: $font-light-color;
  font-size: 12px;
  text-align: center;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: inherit;
  }

  .icp {
    margin-left: 46px;
    margin-right: 47px;
  }

  .record {
    .emblem {
      width: 20px;
      height: 20px;
      position: relative;
      display: inline-block;
      margin-right: 6px;
    }
  }
}

@media (max-width: 767px) {
  .footer-img {
    display: none !important;
  }

  .footer-container {
    display: none !important;
  }

  .footer-phone {
    flex-direction: column;
    padding-right: 0;

    a,
    span {
      padding: 3px 0;
    }
  }
}

// 移动端
$footer-bg-color: #393945;
$copy-right-bg-color: #2c2c34;
$footer-font-color: $font-light-color;
$font-small: 12px;
$font-title: 16px;
$font-mid: 14px;
$copy-right-height: 50px;
$icon-width: 40px;

.mob-only-item {
  display: none;
}

@media (max-width: 768px) {
  .mob-only-item {
    display: block;
  }

  .copyright {
    display: block;
    padding: 18px 0;
    height: auto;
    // line-height: normal;
    font-size: 10px;
    line-height: 15px;
    text-align: center;

    .icp {
      display: block;
      margin: 9px auto;
      padding: 0;
    }

    .record {
      justify-content: center;
    }
  }

  .footer {
    background-color: $footer-bg-color;

    .item-box {
      display: block;
      padding-top: 50px;
      overflow: hidden;
    }

    .link-title {
      margin: 0 auto;
      overflow: auto;

      .character-title {
        display: flex;
        justify-content: center;
        font-size: 14px !important;
        margin-bottom: 20px;
        line-height: 1;

        .left-border {
          border-left: 1px $font-light-color solid;
          margin: 0 12px;
        }

        a {
          color: $font-light-color;
          position: relative;
        }
      }
    }

    .footer-mask {
      display: none;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: RGBA(0, 0, 0, 0.8);
      z-index: 1001;
    }

    .light {
      position: fixed;
      top: 120px;
      left: 50px;
      right: 50px;
      height: 350px;
      background-color: white;
      overflow: auto;

      &.en {
        height: 370px;
      }

      .two-bar-codes {
        height: 147px;
        width: 147px;
        margin: 40px auto 15px;
        position: relative;
      }

      p {
        font-size: 14px;
        color: $font-color;
        line-height: 1.8;
        word-break: keep-all;
        word-wrap: break-word;
        white-space: pre-wrap;
        width: 80%;
        margin: 0 auto;
      }

      .c-btns {
        margin-top: 18px;
      }

      .btn-sure {
        height: 40px !important;
        font-size: 15px;
        margin: auto;
        line-height: 30px;
        width: 180px !important;
      }
    }

    .wechat-img {
      width: 40px;
      height: 40px;
      margin: 40px auto 25px;
      position: relative;
    }

    .footer-phone {
      padding-bottom: 19px;
    }

    .footer-statement {
      padding-bottom: 40px;
    }

    .character-phone {
      line-height: 2;
      font-size: 12px;
      margin-bottom: 0;

      a {
        color: $font-light-color;
      }

      &.language-switch {
        margin-top: 50px;
        margin-bottom: 30px;
        font-size: 13px;
        color: $font-light-color;
        line-height: 1;

        .language {
          display: inline-block;
          cursor: pointer;

          &.no-active {
            color: $footer-locale-color;

            &:hover {
              color: $font-light-color;
            }
          }

          &.language-zh {
            padding-right: 15px;
            border-right: 1px solid $font-light-color;
            margin-right: 15px;
          }
        }
      }
    }
  }

  @media (max-width: 360px) {
    .footer {
      .left-border {
        border-left: 1px $font-light-color solid;
        margin: 0 6px !important;
      }
    }
  }
}

// call
$icon-width: 44px;
$color-dark: #50565c;
$color-primary: $primaryColor;
$font-primary: $font-color;
$font-normal: $font-light-color;

.fixed-r {
  width: 44px;
  position: fixed;
  right: 20px;
  z-index: 999;

  @media (min-width: 768px) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

.fixed-r .box {
  display: none;
  height: 60px;
  width: 154px;
  position: absolute;
  background: url(/v2/images/footer/maskzixun.png) center center / contain no-repeat;
  right: 54px;
  padding: 9px 16px;
}

.en-box {
  width: 263px !important;
  background-image: url(/v2/images/footer/maskzixun-en.png) !important;
}

.fixed-r .box span {
  display: block;
  line-height: 20px;
  margin: auto;
  color: $bg-normal-color;
  font-size: 14px;
}

.fixed-r .call-box {
  @extend .box;
  width: 228px;
  height: 60px;
  background: url(/v2/images/footer/mask-call.png) center center / contain no-repeat;
  right: 54px;

  span {
    display: inline;
  }
}

.en-call-box {
  top: 29px !important;
  height: 100px !important;
  width: 194px !important;
  background-image: url(/v2/images/footer/mask-call-en.png) !important;

  span {
    display: block !important;
  }
}

.radius-icon {
  display: block;
  margin-bottom: 10px;
  width: $icon-width;
  height: $icon-width;
  background: rgba($color-dark, 0.9) center center/$icon-width $icon-width no-repeat;
  border-radius: math.div($icon-width, 2);

  &:hover {
    background-color: rgba($color-primary, 0.9);

    .box {
      display: block;
    }
  }
}

.back {
  transition: all 0.5s;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.fixed-r .back-to-top {
  @extend .radius-icon;
  background-image: url(/v2/images/footer/backtotop.png);
}

.fixed-r .zixun {
  @extend .radius-icon;
  background-image: url(/v2/images/footer/zixun.png);
}

.fixed-r .call {
  @extend .radius-icon;
  background-image: url(/v2/images/footer/call-pc.png);
}

.fixed-r .app-erwei {
  @extend .radius-icon;
  cursor: pointer;
  background-image: url(/v2/images/footer/APP@2x.png);
  line-height: 1;

  .box {
    color: $bg-normal-color;
    width: 140px;
    background: url(/v2/images/footer/bg@2x.png) no-repeat center center;
    height: auto;
    padding: 0 0 20px;
    background-size: contain;
    margin-top: 50%;
    transform: translateY(-50%);

    .erwei-native {
      width: 132px !important;
      padding: 14px !important;
    }

    .text {
      margin-top: -4px;
      font-size: 12px;
      width: 132px;
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .fixed-r.for-pc {
    display: none;
  }

  .fixed-r {
    width: 60px;
    position: fixed;
    right: 9px;
    bottom: 4px;
    z-index: 999;
  }

  .fixed-r .back-to-topmobile {
    margin-bottom: 0;
    width: 60px;
    height: 60px;
    display: block;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .back {
    display: none;
  }

  .fixed-r .zixunmobile,
  .fixed-r .callmobile {
    margin-bottom: 0;
    width: 60px;
    height: 60px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .confirmContainer {
    // 底部二维码弹窗
    .erweiContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .erwei {
        width: 147px;
        height: 147px;
        position: relative;
      }

      > div:not(.erwei) {
        text-align: center;
        line-height: 1.8;
        margin-top: 15px;

        &.follow {
          margin-top: 0;
        }
      }
    }

    :global {
      .ant-modal-confirm-btns {
        float: none;
        margin-top: 18px;
        text-align: center;
      }

      .ant-modal-body {
        padding: 32px 32px 36px;
      }
    }

    .confirmBtn {
      width: 180px;
      height: 40px;
    }
  }
}
