$primaryColor: #0086ff;
$primaryColor-hover: #0078ed;
$greenColor: #32cfa8;
$greenColor-hover: #2ac19c;
$border-color: #e0e0e0;
$font-color: #3e454c;
$font-light-color: #787e85;
$font-desc-color: #808892;
$font-title-color: #e1e7ed;
$font-time-color: #637390;
$font-introduce-color: #c9d2de;
$font-more-color: #4d93f8;
$font-danger-color: #eb6f6f;
$font-desc-color-new: #fafcff;
$font-normal-advantage-color: #dbeeff;
$bg-normal-color: #fff;
$bg-purple-color: #f1f5fe;
$bg-primary-color: #f7f9ff;
$bg-main-color: #f4f4f8;
$bg-purple-color: #f1f5fe;
$bg-leftTab-color: #f4f4f8;
$bg-scene-color: #f6f9ff;
$bg-tab-color: #f5f4f9;
$bg-advantage-color: #f3f6fb;
// header footer 字体颜色
$header-font-hover-color: rgba(250, 250, 250, 0.9);
$footer-locale-color: #585c67; // 移动端切换语言
$border-more-color: #e9e9e9;
//新按钮颜色
$new-greenColor: #00d6c6;
$new-greenColor-hover: #00c4b1;
$new-blueColor: #0086ff;
$new-blueColor-hover: #0078ed;

$text-color: #3e434d;
$bg-deep-color: #393946;
$desc-color: #c5c7c9;
$line-color: #3d3d3d;
$scene-hover-color: #004dd6;
$item-desc-color: #e6e9ec;
$circle-color: #d9d9d9;
