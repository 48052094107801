@import 'styles/var.scss';
@import 'styles/mixin.scss';

// 查看更多
.viewMore {
  width: 100%;
  height: pr(80);
  font-size: pr(30);
  font-weight: 400;
  color: $font-light-color;
  border: 1px solid #979797;

  &:hover,
  &:focus {
    color: $font-light-color;
    border-color: #979797;
  }
}

.banner,
.nav,
.newBtn {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $bg-normal-color;
  border-radius: 4px;
  box-shadow: none;

  &.default {
    background-color: $primaryColor;
    border-color: $primaryColor;

    &:hover {
      background-color: $primaryColor-hover;
      border-color: $primaryColor-hover;
    }
  }

  &.green {
    background-color: $greenColor;
    border-color: transparent;

    &:hover {
      background-color: $greenColor-hover;
      border-color: $greenColor-hover;
    }
  }
}

// banner button
.banner {
  font-size: pr(16);
}

// 导航button
.nav {
  width: pr(100);
  height: pr(32);
  font-size: pr(14);
}

//八大页面新颜色按钮
.newBtn {
  background-color: $new-blueColor;
  border-color: $new-blueColor;
  font-size: pr(16);

  &:hover {
    background-color: $new-blueColor-hover;
    border-color: $new-blueColor-hover;
  }

  &.green {
    background-color: $new-greenColor;
    border-color: transparent;

    &:hover {
      background-color: $new-greenColor-hover;
      border-color: $new-greenColor-hover;
    }
  }
}

@media (min-width: 768px) {
  .banner,
  .newBtn {
    width: pr(180);
    height: pr(44);
  }
}

@media (max-width: 767px) {
  .banner,
  .newBtn {
    width: 220px;
    height: pr(80);
    font-size: pr(32);
  }
}
