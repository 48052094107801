@import 'styles/var.scss';
@import 'styles/mixin.scss';

html {
  height: auto;

  body {
    margin: 0;
    padding: 0;

    font-family: pingfang SC, Tahoma, Helvetica, Arial, Microsoft Yahei, 'Helvetica Neue', Helvetica, '黑体', '宋体', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    height: auto;
    line-height: 1.5715;
    font-size: 14px;
    color: $font-color;
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: none;
  }

  ul,
  dl,
  ol {
    margin: 0 !important; // 覆盖antd less
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }

  a,
  a:hover,
  a:focus {
    text-decoration: none;
    color: $font-light-color;
  }

  #root,
  .container {
    height: auto;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  /* 分页 */
  .ant-pagination {
    // .ant-pagination-jump-next,
    // .ant-pagination-jump-next ~ li ,
    // .ant-pagination-jump-prev{
    //   display: none;
    // }

    // .ant-pagination-item:has(+ l) {
    //   display: none;
    // }
    li.ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      margin-left: 7px;
      margin-right: 7px;
    }

    li.ant-pagination-item {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      &:not(.ant-pagination-item-active) {
        border: 1px solid $border-color;

        &:hover {
          border: 1px solid $primaryColor;
        }
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      background: #ffffff;

      .ant-pagination-item-link {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .ant-pagination-item-active {
      background: $primaryColor;
      border-radius: 4px;

      a {
        font-weight: 400;
        color: #ffffff;
        display: flex;
        height: 100%;
        justify-content: center;
      }
    }
  }

  .ant-btn {
    transition: none;
    box-shadow: none;
  }

  .ant-btn:hover {
    transition: all, 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

#newBridge {
  display: none !important;
}

a.ant-btn {
  padding-top: 0 !important;
}

.ant-btn-primary {
  text-shadow: none;
}

@keyframes progress {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
