@use "sass:math";
$baseFontSize: 100;

@import 'styles/var.scss';

// px to rem
@function pr($px) {
  @return math.div($px, $baseFontSize) * 1rem;
}

@mixin ellipsis {
  word-wrap: normal;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

%flex-column-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// pc top:40 bottom:120或者90
// 移动 top 60 bottom:90
%container {
  max-width: pr(1080);
  margin: 0.6rem auto 0.9rem;
}

%container-width-1240 {
  width: pr(1240);
}

@media (max-width: 767px) {
  %container {
    width: auto;
    margin: 0;
  }
}

@media (max-width: 1240px) {
  %container {
    max-width: pr(900);
    margin: 0 auto;
  }
}

@mixin product-content {
  margin: pr(80) auto;
  display: flex;
  width: pr(1324);
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@mixin border_1px($color) {
  position: relative;

  &:after {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid $color;
    content: '';

    //  检测不同屏幕 实现移动端1px边框
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5) {
      -webkit-transform: scaleY(0.7);
      transform: scaleY(0.7);
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
  }
}

// 1px 像素
.border__px {
  position: relative;

  &:after {
    display: block;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    z-index: 9;

    //  检测不同屏幕 实现移动端1px边框
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5) {
      -webkit-transform: scaleY(0.7);
      transform: scaleY(0.7);
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
  }
}

.border__px-before {
  position: relative;

  &:before {
    display: block;
    height: 1px;
    position: absolute;
    left: 42px;
    right: 0;
    top: 0;
    background-color: $border-color;
    content: '';
    z-index: 999;

    //  检测不同屏幕 实现移动端1px边框
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5) {
      -webkit-transform: scaleY(0.7);
      transform: scaleY(0.7);
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
  }
}

.border__px-before1 {
  position: relative;

  &:before {
    display: block;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: $border-color;
    content: '';
    z-index: 999;

    //  检测不同屏幕 实现移动端1px边框
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5) {
      -webkit-transform: scaleY(0.7);
      transform: scaleY(0.7);
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
  }
}

.border-children1 {
  &:after {
    display: block;
    height: 1px;
    position: absolute;
    left: 42px;
    right: 0;
    bottom: 0;
    background-color: $border-color;
    content: '';
    z-index: 9;

    //  检测不同屏幕 实现移动端1px边框
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5) {
      -webkit-transform: scaleY(0.7);
      transform: scaleY(0.7);
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
  }
}

.border-children2 {
  &:after {
    display: block;
    height: 1px;
    position: absolute;
    left: 60px;
    right: 0;
    bottom: 0;
    background-color: $border-color;
    content: '';
    z-index: 9;

    //  检测不同屏幕 实现移动端1px边框
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5) {
      -webkit-transform: scaleY(0.7);
      transform: scaleY(0.7);
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
  }
}

.for-mobile {
  display: none;
}

@media (max-width: 767px) {
  .for-pc {
    display: none !important;
  }

  .for-mobile {
    display: block;
  }
}

// 自适应宽度 相当于bootstrap中的container
%inner-container {
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;

  @media (max-width: 767px) {
    padding: 0;
  }

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1270px) {
    width: 1240px;
  }
}

//八大行业的content样式
%content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: pr(1240);
}

@mixin title {
  font-weight: 500;
  font-size: pr(44);
  line-height: pr(62);

  @media (max-width: 767px) {
    font-size: pr(52);
    line-height: pr(52);
  }
}

@mixin desc {
  font-weight: 500;
  font-size: pr(24);
  line-height: pr(33);

  @media (max-width: 767px) {
    font-size: pr(28);
    line-height: pr(44);
  }
}
