@import 'styles/var.scss';
@import 'styles/mixin.scss';

.sale-bar {
  background-color: $bg-deep-color;
  height: 36px;
  overflow: hidden;
  color: #a3aab5;

  .inner {
    @extend %inner-container;
    padding: 0;
  }
}

.header-bar {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  background-color: $bg-normal-color;
  margin: 0;
  border: none;
  position: relative;
  border-radius: 0;
  height: 64px;
  line-height: 64px;

  .header-container {
    @extend %inner-container;
    padding: 0;
  }
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 134px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      a,
      img {
        width: 100%;
        height: 100%;
      }
    }

    .left {
      flex: 0 0 700px;
      min-width: 640px;
      display: flex;
      align-items: center;

      &.en {
        flex: 0 0 900px;
      }
    }

    .right {
      margin-left: 20px;
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn-suport {
        margin-left: 20px;
      }
    }

    .nav {
      display: flex;
      padding-left: 30px;

      .menu {
        display: flex;
        padding: 0 15px;
        position: relative;

        .nav-bottom-line {
          position: absolute;
          width: 80%;
          height: 2px;
          background-color: transparent;
          left: 50%;
          top: 62px;
          z-index: 10;
          transform: translateX(-50%);
        }

        &:hover .href {
          color: $new-blueColor;
        }

        &:hover .caret {
          color: $new-blueColor;
          transform: rotate(180deg);
        }

        &:hover .nav-bottom-line {
          background-color: $new-blueColor;
        }

        &:hover .dropdown-menu {
          display: flex;
          padding: 15px;
          height: 250px !important;
          overflow: initial !important;
          transition: all 0.3s;
          padding-top: 40px;
        }

        .href {
          height: 100%;
          font-size: 16px;
          color: $text-color;
        }

        .li {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .dropdown-menu {
          position: absolute;
          display: flex;

          background-color: $bg-normal-color;
          margin-right: 84px;
          top: 64px;
          left: 0;
          height: 0;
          overflow: hidden;
          box-shadow: 1px 5px 15px rgba(0, 0, 0, 0.1);

          > li {
            position: relative;
            display: block;
            line-height: 40px;

            > a {
              font-size: 16px;
              font-weight: 600;
              color: $font-color;
            }

            ul {
              margin-right: 120px !important;

              li {
                a {
                  font-size: 16px;
                  color: $text-color;

                  &:hover {
                    color: $new-blueColor;
                  }
                }
              }
            }
          }

          &.menu-solution {
            left: -84px;

            .uls {
              display: flex;
              justify-content: center;
            }

            ul {
              min-width: 128px;

              li {
                // a {
                //   display: block;
                //   padding: 7px 20px;
                //   line-height: 1.42857143;
                //   &:hover {
                //     background-color: $primaryColor;
                //   }
                // }
              }
            }

            .string {
              margin-top: 20px;
              width: 1px;
              height: 140px;
              transform: scaleX(0.5);
              background: rgba(120, 126, 133, 0.3);
            }
          }
        }

        .row {
          justify-content: flex-start;
          flex-direction: column;
          height: 0;
          cursor: pointer;

          > li {
            width: 180px;
            overflow: visible;

            .title {
              position: relative;
              top: 0;
              min-width: 180px;
              font-size: 16px;
              color: $text-color;

              a {
                color: $text-color;
              }

              &::after {
                position: absolute;
                display: block;
                content: '';
                width: 1px;
                bottom: -25px;
                height: 50px;
                right: 0;
                background-color: #dee0e3;
              }
            }

            .enTitle {
              min-width: 330px;
            }

            .items {
              display: none;
              position: absolute;
              top: 0;
              left: 180px;
              width: 700px;

              li a {
                font-size: 16px;
              }

              &:hover {
                height: 250px;
              }
            }

            &:nth-last-child(2) .items {
              top: -40px;
            }

            &:last-child .items {
              top: -80px;
            }

            &:hover .title a,
            &:hover .title .open {
              color: $primaryColor;
            }

            &:hover .items {
              display: block;
            }
          }

          > li > ul {
            padding-left: 70px;
            margin-right: 0px !important;
          }
        }

        .rowEn {
          > li > ul {
            padding-left: 240px;
          }
        }
      }

      .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 5px;
        vertical-align: middle;
        border-top: 3px dashed;
        border-top: 3px solid \9;
        border-right: 3px solid transparent;
        border-left: 3px solid transparent;
        transition: transform ease 0.3s;
      }
    }
  }
}

.sale {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 36px;

  &-li {
    display: flex;
    align-items: center;

    margin-left: 43px;
    height: 100%;
    line-height: 36px;

    color: rgba(250, 250, 250, 0.9);
    font-size: 12px;

    &.language-switch {
      font-size: 12px;
      line-height: 9px;
      height: 9px;
    }

    a {
      color: rgba(250, 250, 250, 0.9);

      &:hover {
        color: $new-blueColor;
      }
    }

    a,
    span {
      height: 100%;
    }
  }
}

// 移动端菜单不展示
.moblieMask {
  display: none;
  overflow: auto;
  height: 0;
  transition: height 0.2s ease-in-out;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 57px;
  width: 100%;

  &.active {
    display: block;
    height: 100vh;
  }
}

.toggleMenu,
.download-app-tips {
  display: none;
}

@media (max-width: 767px) {
  .header-container {
    background: #f8f8f8;
  }

  .header-bar {
    padding: 0;
    height: 57px;
    line-height: 57px;
  }

  // 打开app
  .download-app-tips {
    background: #f2f2f2;
    font-size: 20px;
    height: 70px;
    display: flex;
    align-items: center;

    &.hide {
      display: none;
    }

    .left {
      flex: 1;
      display: flex;
      align-items: center;

      .btn-delete {
        flex: 0 0 36px;
        width: 36px;
        height: 36px;
        cursor: pointer;
      }

      .icon-xiaoyu {
        flex: 0 0 54px;
        width: 54px;
        height: 54px;
      }

      .main-title {
        margin-left: 10px;

        .header-title {
          font-size: 14px;
          color: #333333;
          margin-bottom: 3px;
        }

        .header-desc {
          font-size: 12px;
          color: #999999;
        }
      }
    }

    .right {
      flex: 0 0 96px;
      text-align: left;

      .open-app {
        width: 82px;
        height: 28px;
        display: inline-block;
        line-height: 28px;
        cursor: pointer;
        background: $primaryColor;
        border-radius: 4px;
        text-align: center;
        font-size: 13px;
        color: #ffffff;
      }
    }
  }

  .header {
    &-container {
      .logo {
        padding-left: 12px;

        a {
          width: 108px;
          box-sizing: content-box;
        }
      }

      .nav {
        display: none !important;
      }

      .left {
        height: 57px;
      }

      .right {
        display: none !important;
      }
    }

    // 移动端
    .toggleMenu {
      display: flex;
      position: absolute;
      align-items: center;
      width: 20px;
      right: 25px;
      z-index: 1;

      :global {
        display: block;

        .line-top,
        .line-middle,
        .line-bottom {
          display: block;
          width: 20px;
          height: 3px;
          background-color: #777;
          margin: 3px 0;
          transition: 0.4s;
        }
      }

      &.active {
        :global {
          .line-top {
            transform: rotate(45deg) translate(2px, 7px);
          }

          .line-middle {
            opacity: 0;
          }

          .line-bottom {
            transform: rotate(-45deg) translate(1px, -7px);
          }
        }
      }
    }

    .moblieMask {
      display: block;
      position: absolute;
    }
  }

  .sale-bar {
    display: none !important;
  }
}

// 移动端样式
.mob-only-item {
  background: $bg-normal-color;
  width: 100%;
}

.mob-only-item > li {
  position: relative;
  font-size: 16px;

  > a {
    height: 60px;
    display: block;
    line-height: 60px;
    text-align: left;
    padding: 0 20px 0 24px;
    color: $font-color;

    &:active {
      background: #dfe0e3;
    }
  }

  // 定义公共样式
  .icon-common {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 20px;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;

    &:global {
      &.active {
        transform: rotate(180deg);
      }
    }

    img {
      width: 100%;
    }
  }

  // 一级标题图标
  .icon-drawDown {
    @extend .icon-common;
    top: 10px;
  }

  // 二级标题图标
  .icon-drawDown-children {
    @extend .icon-common;
    top: 7px;
  }

  .solution_menu {
    max-height: 0;
    display: block;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;

    &:global {
      &.active {
        display: block;
        height: auto;
        max-height: 500px;
      }
    }

    > li {
      a {
        color: $font-color;
        padding-left: 42px;
        display: block;
        height: 50px;
        text-align: left;
        font-size: 15px;
        line-height: 50px;

        &:active {
          background: #dfe0e3;
        }
      }
    }
  }

  .solution_menu--third {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;

    &:global {
      &.active {
        display: block;
        height: auto;
        max-height: 351px;
      }
    }

    > li {
      a {
        padding-left: 60px;
        display: block;
        height: 50px;
        color: $font-light-color;
        font-size: 14px;
        line-height: 50px;
        text-align: left;

        &:active {
          background: #dfe0e3;
        }
      }
    }
  }

  &.mobile-menu {
    a {
      height: 80px;
      line-height: 80px;
    }
  }
}

.open {
  display: inline-block;
  margin-left: 5px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
