.container {
  // 给菜单留出空间
  padding-top: 100px;
  overflow: hidden;

  .headerImage {
    width: 6rem;
    height: 6rem;
  }

  .headerHomeImage {
    width: 8rem;
    height: 8rem;
  }

  .backToHome {
    margin: 1rem 0 2rem;
  }
  > .main {
    min-height: 50vh;
    // overflow: auto;
    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 767px) {
  .container {
    // 给菜单留出空间
    padding-top: 57px;
    &.bigPadding {
      padding-top: 127px;
    }
    > .main {
      min-height: 50vh;
    }
  }
}
